




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import ListView from '@/components/helper/list-view.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Payment from '@/store/entities/lawyers/payment';
import FormBase from '@/components/helper/form-base.vue';

class PageLawyerRequest extends PageRequest {
    document: string = '';
    name: string = '';
    alive: string = 'true';
    condition: number = null;
}

@Component({
    components: { ModalView, FormBase, ListView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            if (val) {
                obj.initForm();
            }
            obj.modalShow = val;
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class PaymentFormForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    payment: Payment = new Payment();
    pagerequest: PageLawyerRequest = new PageLawyerRequest();
    modalShow: boolean = false;
    selectedItem: any = null;
    filterForm: boolean = true;
    modalSetting: any = {
        entity: "payment",
        title: 'Pago de Habilitados',
        width: "90%",
        saveText: 'Agregar Pago',
        disabledSave: true,
        showForm: false
    };

    filterItems = [
        this.gItem({ type: 'text', prop: 'document', label: 'Buscar por Numero de Documento o Matricula', size: 4, onchange: this.loadLawyers, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por Nombres o Apellidos', size: 4, onchange: this.loadLawyers, hideDetails: true }),
        this.gItem({ type: 'object', prop: 'condition', label: 'Condicion', generalType: 'L_CONDITION', size: 4, onchange: this.loadLawyers, hideDetails: true }),
    ]

    items = [
        this.gItem({ type: 'date', prop: 'payment_date', label: 'Fecha de Pago', size: 6, required: true }),
        this.gItem({ type: 'spacer', size: 6 }),
        this.gItem({ type: 'object', prop: 'condition_id', label: 'Condicion', generalType: 'L_CONDITION', required: true, size: 6,  }),
        this.gItem({ type: 'date', prop: 'expiration_date', label: 'Nueva fecha de Expiracion', size: 6, required: true, mask: '####' }),
        this.gItem({ type: 'number', prop: 'amount', label: 'Monto', size: 12, defaultValue: 0, prefixSelect: true, prefixProp: 'currency_id', itemText: 'sign', entity: "currency", class: 'big-size' }),
    ]

    columns = [
        this.gCol('code', 'Matricula', 'text', '60px', false),
        this.gCol('document', 'Documento', 'text', '60px', false),
        this.gCol('fullname', 'Nombre', 'text'),
        this.gCol('condition_message', 'Condicion', 'text', null, false),
    ]
    
    async initForm() {
        this.payment = Util.extend(true, {}, this.$store.state.payment.editModel);

        this.selectedItem = null;
        await this.loadLawyers();
        await this.getLastCode();
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    async loadLawyers() {           

        let list = (this.$refs.list as any);
        if (list != undefined)
            list.selected = [];

        this.$store.commit('lawyer/setList',[]);

        await this.$store.dispatch({
            type: 'lawyer/getAll',
            data: this.pagerequest
        });
        this.modalSetting.disabledSave = true;
    }

    onSelectItems(items) {
        this.modalSetting.disabledSave = true;
        this.selectedItem = items[0];
        if (this.selectedItem != undefined) 
            this.payment.lawyer_id = this.selectedItem.id;

        if (this.selectedItem != undefined && this.selectedItem != null) {
            this.modalSetting.disabledSave = false;
        }
    }

    async getLastCode() {
        let code = await this.$store.dispatch({ type: 'payment/getLastCode' });
        this.payment.code = code;
    }

    async save() {
        let form = (this.$refs.entityForm as any);
        if (form.validate()) {
            let model = Util.extend(true, {}, this.payment);
            
            let newModel = await this.$store.dispatch({
                type: 'payment/create',
                data: model
            });

            this.$emit('save-success');
            Util.abp.notify.success(this, 'Pago registrado, ' + newModel.condition_message);
            this.$emit('input', false);
        }
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {

    }

    created() {
    }
}
