


































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import PaymentForm from './payment-form.vue';

class PagePaymentRequest extends PageRequest {
    lawyer: number = null;
    date_from: string = new Date().toISOString().substr(0, 8) + '01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ListView, FormBase, PaymentForm }
})
export default class Payments extends ListBase {
    entity: string = 'payment';
    pagerequest: PagePaymentRequest = new PagePaymentRequest();
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'object', prop: 'lawyer', label: 'Buscar Agremiado por Nombre, Matricula o Documento', itemText: 'description_full', autocomplete: true, searchLen: 2, size: 6, returnObject: false, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'spacer', size: 2 }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Pagos Desde', size: 2, hideDetails: true }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true }),

    ]

    columns = [
        this.gColO({ type: 'text', value: 'code', text: 'Codigo', main: true }),
        this.gCol('lawyer.code', 'Matricula', 'text'),
        this.gCol('lawyer.document', 'Documento', 'text'),
        this.gCol('lawyer.fullname', 'Agremiado', 'text'),
        this.gCol('payment_date', 'Fecha de Pago', 'text'),
        //this.defaultActions()
    ];

    get isPayment() {
        return Util.abp.session.isAdmin || Util.abp.session.isPayment();
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    } 

    async afterSave() {
        this.createModalShow = false;
        let list: any = this.$refs.listView
        await list.getpage();
    }
    
    async created() {        
    }
}